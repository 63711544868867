import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

//intializing the apolloclient, passing  its constructor with objetcs - url and cache
const client = new ApolloClient({
  //url of the graphql server
  uri:
    process.env.REACT_APP_GRAPHQL_URL ||
    "https://db-be-dev-qpvrxjuuzq-el.a.run.app/graphql",
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
