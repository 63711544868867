import React, { Fragment, useState, useEffect } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const GetStarRating = gql`
  mutation starRating($orderId: String!, $starRating: Float!) {
    starRating(orderId: $orderId, starRating: $starRating) {
      storeId
      orderId
      storeName
      rating
    }
  }
`;

const GetStoredRating = gql`
query GetOrderDetailsByOrderId($orderId: String!, $billVisited: Boolean) {
  GetOrderDetailsByOrderId(orderId: $orderId, billVisited: $billVisited) {
      rating
    }
  }
`;

const StarRating = () => {
  const { id } = useParams();
  const [disable, setDisable] = React.useState(false);
  const client = useApolloClient();
  const [storedRating, setStoredRating] = useState(-1);
  const [isError, setisError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      client
        .query({
          query: GetStoredRating,
          variables: {
            orderId: id,
            billVisited: true,
          },
        })
        .then((result) => {
          setStoredRating(result.data.GetOrderDetailsByOrderId.rating)
        })
        .catch((error) => {
          setisError(true);
        });
    };
    getData();
  }, []);

  const FetchData = async (rating: Number) => {
    const getData = async () => {
      client.mutate({
        mutation: GetStarRating,
        variables: {
          orderId: id,
          starRating: rating,
        },
      });
    };
    getData();
    setDisable(true);
  };

  return (
    <>
      {storedRating!== -1 ? (
        <>
          {disable ? (
            <div className="App-left">
              <b>Thank you for rating at dailyBee.</b>
            </div>
          ) : (
            <div className="App-left">
              <b>Order Rating: {storedRating}</b>
            </div>
          )}
        </>
      ):(
        <label>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <div className="App-normal">
            <b> Rate your DailyBee experience:</b>
            <br />
            <button
              disabled={disable}
              type="button"
              onClick={() => {
                FetchData(1);
              }}
            >
              <span className="emoji" role="img" aria-label="sad">
                😞
              </span>{" "}
            </button>
            <button
              disabled={disable}
              type="button"
              onClick={() => {
                FetchData(2);
              }}
            >
              <span className="emoji" role="img" aria-label="Not satisfied">
                😕
              </span>{" "}
            </button>
            <button
              disabled={disable}
              type="button"
              onClick={() => {
                FetchData(3);
              }}
            >
              <span className="emoji" role="img" aria-label="Neutral">
                😐
              </span>{" "}
            </button>
            <button
              disabled={disable}
              type="button"
              onClick={() => {
                FetchData(4);
              }}
            >
              <span className="emoji" role="img" aria-label="grinning">
                😀
              </span>{" "}
            </button>
            <button
              disabled={disable}
              type="button"
              onClick={() => {
                FetchData(5);
              }}
            >
              <span className="emoji" role="img" aria-label="happpy face">
                😊
              </span>{" "}
            </button>
          </div>
        </label>
      )}
    </>
  );
};

export default StarRating;
