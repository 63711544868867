import React from "react";
import { Route, Switch } from "react-router-dom";
import OrderTable from "./OrderTable";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <h1>Home page</h1>
        </Route>
        <Route exact path="/o/:id" component={OrderTable} />
      </Switch>
    </>
  );
};

export default App;
